import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss'
import Footer from './Footer';
import Navbar from './Navbar';
import Home from './Home';
import ItemList from './ItemList';
// import ProductPage from './ProductPage';
import WhatsApp from './WhatsApp';
import ScrollToTop from './ScroollToTop';
import AboutUs from './AboutUs';
// import Payment from './Payment';
import Logo from './Logo';
import BankAccounts from './BankAccounts';

function App() {
  return (
    <div className="App">
       <Router>
            <div>
              <Navbar />
              <Switch>
                <ScrollToTop>
                  <Route path="/" exact component={Home} />
                  <Route path="/urunler" exact component={ItemList} />
                  {/* <Route path="/urunler/:id" exact component={ProductPage} /> */}
                  <Route path="/hakkimizda" exact component={AboutUs} />

                  {/* <Route path="/odeme/:id" exact component={Payment} /> */}
                  <Route path="/banka-hesaplari" exact component={BankAccounts} />
                </ScrollToTop>
              </Switch>
              <WhatsApp src={process.env.PUBLIC_URL + '/img/whatsapp.svg'} alt="WhatsApp logo" />

              <Footer />
            </div>
          </Router>
    </div>
  );
}

export default App;
