import React, { Fragment } from 'react';
import Gallery from './Gallery';
import Header from './Header';
import ItemList from './ItemList';
import './ProductPage.scss'

const Home = () => {
   return (
      <Fragment>
         <Header />
         <ItemList />
         <div className="container">
            <div className="product__info__description">
               <h3 style={{ backgroundColor: '#FE4F34', color: "white" }} className="mt-5">Değerli Müşterilerimizin Dikkatine!</h3>
               <ul className="mt-5">
                  <li><b>Etil Hizmet destek ekibi; merak ettiğiniz soruları cevaplandırmak ve 7 gün 24 saat hızlı sipariş verebilmenizi sağlamak için burada.</b></li>
                  <li><b>Etil arayışınıza doğru ve profesyonel çözümler üretiyoruz. Güvenli, hızlı ve sorunsuz bir şekilde ürün satışı almanızı sağlıyoruz.</b></li>
                  <li><b>TÜRKİYE’NİN HER NOKTASINA ÜRÜNLERİMİZİ ULAŞTIRMAKTAYIZ.</b></li>
               </ul>
            </div>
         </div>
         <Gallery />
      </Fragment>
   );
}

export default Home;
