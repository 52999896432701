import React from 'react';
import { Instagram } from "@mui/icons-material";

import './SocialMedia.scss';

const SocialMedia = ({ href, text }) => {
   return (
      <a className="navbar__socialMedia" target='_blank' rel="noreferrer" href={href}>
         <Instagram style={{ fontSize: "5rem" }} />
         <span className="navbar__socialMedia__brand">{text}</span>
      </a>
   )
}

export default SocialMedia;
