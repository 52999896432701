import React from 'react';
import './Footer.scss';
import "./Logo.scss"
import SocialMedia from './SocialMedia';
import { AlternateEmail, Call, LocationOn } from "@mui/icons-material";

const Footer = () => {
   return (
      <footer className="footer">
         <div className="container-fluid">
            <div className="row">
               <div className="col-lg-4 mb-md-3 d-flex align-items-center justify-content-center">
                  <div className="footer__contact">
                     <h2>Adresimiz</h2>
                     <hr />
                     <ul>
                        <li><LocationOn /><h3>Tüfekçi Yusuf Bulvarı No:152</h3> </li>
                        <li><Call /><h3><a href="tel://905396210387">+90 539 621 03 87</a></h3></li>
                        <li><AlternateEmail /><h3><a href="mailto:etilalkol@gmail.com">etilalkol@gmail.com</a></h3></li>
                     </ul>
                  </div>
               </div>
               <div className="col-lg-4 d-lg-block d-sm-none d-flex align-items-center justify-content-center">
               </div>
               <div className="col-lg-4 mt-3 mt-lg-0 d-flex align-items-center justify-content-center">
                  <div className="footer__misc">
                     <SocialMedia className="footer__socialMedia" href="https://instagram.com/elitshop2024" text="@etilalkol" />
                     <img className="footer__misc_guvenli" src={process.env.PUBLIC_URL + '/img/guvenli-alisveris.svg'} alt="Guvenli Alisveris" />
                     <img className="footer__misc_quality" src={process.env.PUBLIC_URL + '/img/quality.png'} alt="%100 Quality" />
                  </div>
               </div>
               <div className="col-12 footer__rights mt-3"><p> Etil Alkol Tüm Hakları Saklıdır.</p></div>
            </div>
         </div >
      </footer >
   )
}

export default Footer;
