import React from 'react';
import './Gallery.scss'

export const Gallery = () => {
   return (
      <div className="section-meals container-fluid">
         <div className="row">
            <ul className="meals-showcase clearfix">
               <li>
                  <figure className="meal-photo">
                     <img src={process.env.PUBLIC_URL + '/img/1.jpg'} alt="etil alkol" />
                  </figure>
               </li>
               <li>
                  <figure className="meal-photo">
                     <img src={process.env.PUBLIC_URL + '/img/2.jpg'} alt="etil alkol" />
                  </figure>
               </li>
               <li>
                  <figure className="meal-photo">
                     <img src={process.env.PUBLIC_URL + '/img/3.jpg'} alt="etil alkol" />
                  </figure>
               </li>
               <li>
                  <figure className="meal-photo">
                     <img src={process.env.PUBLIC_URL + '/img/4.jpg'} alt="etil alkol" />
                  </figure>
               </li>
            </ul>
         </div>
      </div>
   )
}

export default Gallery;
