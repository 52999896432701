import React from 'react';
import './AboutUs.scss'

const AboutUs = () => {
   return (
      <section className="about">
         <div className="container">
            <div className="row">
               <div className="col-lg-6 about__img">
                  <img src={process.env.PUBLIC_URL + '/img/1.jpg'} alt='About us' />
               </div>
               <div className="col-lg-6 about__us">
                  <h1>Etil Alkol</h1>
                  <p>
                     Etil Hizmet olarak; etil arayışınıza doğru ve profesyonel çözümler üretiyoruz. Güvenli, hızlı ve sorunsuz bir şekilde ürün satışı almanızı sağlıyoruz.
                     Etil Hizmet destek ekibi; merak ettiğiniz soruları cevaplandırmak ve 7 gün 24 saat hızlı sipariş verebilmenizi sağlamak için burada.
                     WhatsApp üzerinden sipariş verebilirsiniz.
                  </p>
               </div>
            </div>
         </div>
      </section>
   )
}

export default AboutUs;
