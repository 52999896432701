import React from 'react';
import './BankAccounts.scss'
import { Call } from "@mui/icons-material";

const BankAccounts = () => {
   return (
      <section className="accounts">
         <div className="container">
            <div className="row">
               <div className="col">
                  <h1  >Banka Hesaplarımız</h1>
                  <table class="minimalistBlack">

                     <thead>
                        <tr>
                           <th>Banka</th>
                           <th>IBAN</th>
                           <th>Hesap Sahibi</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>Akbank</td>
                           <td>TR00 0000 0000 0000 0000 0000 00</td>
                           <td>Gokhan Pehlivan</td>
                        </tr>
                     </tbody>
                  </table>
                  <div className="accounts__button">
                     <button onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `https://api.whatsapp.com/send?phone=905517462326`;
                     }} className="shopier" > <Call /> <h4>EFT ile Ödemeyi Bildir</h4></button>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default BankAccounts;
