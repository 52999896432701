import React from 'react';
import "./Header.scss";
import SlideItem from './SlideItem';

const Header = () => {
   return (
      <div className="header">
         <SlideItem alt="slide-1" src={'/img/slayt-1.webp'} />
      </div >
   );
}

export default Header;
