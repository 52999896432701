import React from 'react';
import Item from './Item';
import "./ItemList.scss";
import { Link } from 'react-router-dom';

const ItemList = () => {
   const alkoller = [
      {
         id: 1,
         title: "Volan Etil 5 litre",
         price: 800,
         imgUrl: "/img/volan-5.png"
      },
      {
         id: 2,
         title: "Alkomed Etil 5 litre",
         price: 800,
         imgUrl: "/img/alkomed-5.png"
      },
      {
         id: 3,
         title: "Neva Etil 5 litre",
         price: 800,
         imgUrl: "/img/neva-5.png"
      },
      {
         id: 4,
         title: "Dr. Derman Etil 5 litre",
         price: 800,
         imgUrl: "/img/derman-5.png"
      }
   ];

   const kampanyalar = [
      {
         id: 1,
         title: "2 Adet Alkomed Etil (5 Litrelik)",
         price: <span>
            <span style={{ textDecoration: "line-through" }}>1800</span>
            <span style={{ color: "red" }}> 1500</span>
         </span>,
         imgUrl: "/img/2alkomed.jpg"
      },
      {
         id: 2,
         title: "2 Adet Dr. Derman Etil (5 Litrelik)",
         price: <span>
            <span style={{ textDecoration: "line-through" }}>1800</span>
            <span style={{ color: "red" }}> 1500</span>
         </span>,
         imgUrl: "/img/2derman.jpg"
      },
      {
         id: 3,
         title: "2 Adet Volan Etil (5 Litrelik)",
         price: <span>
            <span style={{ textDecoration: "line-through" }}>1800</span>
            <span style={{ color: "red" }}> 1500</span>
         </span>,
         imgUrl: "/img/2volan.jpg"
      },
      {
         id: 4,
         title: "2 Adet Neva Etil (5 Litrelik)",
         price: <span>
            <span style={{ textDecoration: "line-through" }}>1800</span>
            <span style={{ color: "red" }}> 1500</span>
         </span>,
         imgUrl: "/img/2neva.jpg"
      },
      {
         id: 5,
         title: "4 Adet Volan Etil (5 Litrelik)",
         price: <span>
            <span style={{ textDecoration: "line-through" }}>3600</span>
            <span style={{ color: "red" }}>2800</span>
         </span>,
         imgUrl: "/img/4adetVolan.jpeg"
      },
      {
         id: 6,
         title: "4 Adet Neva Etil (5 Litrelik)",
         price: <span>
            <span style={{ textDecoration: "line-through" }}>3600</span>
            <span style={{ color: "red" }}>2800</span>
         </span>,
         imgUrl: "/img/4adetneva.jpeg"
      },
      {
         id: 7,
         title: "4 Adet Dr. Derman Etil (5 Litrelik)",
         price: <span>
            <span style={{ textDecoration: "line-through" }}>3600</span>
            <span style={{ color: "red" }}>2800</span>
         </span>,
         imgUrl: "/img/4drderman.jpeg"
      },
      {
         id: 8,
         title: "4 Adet Alkomed Etil (5 Litrelik)",
         price: <span>
            <span style={{ textDecoration: "line-through" }}>3600</span>
            <span style={{ color: "red" }}>2800</span>
         </span>,
         imgUrl: "/img/4adetalkomed.jpeg"
      },
      {
         id: 9,
         title: "4 Adet Medicol Etil 5 Litre Original Moldova (İTHAL) (Zernoff)",
         price: <span>
            <span style={{ textDecoration: "line-through" }}>3600</span>
            <span style={{ color: "red" }}>2800</span>
         </span>,
         imgUrl: "/img/4adetmedicol.jpeg"
      }
   ];

   const renderList = alkoller.map((alkol, index) => {
      return (
         <div className="item col-xl-3 col-lg-4 col-6" key={alkol.id.toString()}>
            <Link to={`#`}>
               <Item 
                  src={process.env.PUBLIC_URL + alkol.imgUrl} 
                  title={alkol.title} 
                  price={alkol.price} 
                  id={alkol.id} 
                  unit={false} 
               />
            </Link>
         </div>
      );
   });
   console.log("rest");
   const campaigns = kampanyalar.map((alkol, index) => {
      return (
         <div className="item col-xl-3 col-lg-4 col-6" key={alkol.id.toString()}>
            <Link to={`#`}>
               <Item 
                  src={process.env.PUBLIC_URL + alkol.imgUrl} 
                  title={alkol.title} 
                  price={alkol.price} 
                  id={alkol.id} 
                  unit={false} 
               />
            </Link>
         </div>
      );
   });

   

   return (
      <section className="products">
         <div className="container">
            <div className="row">
               <h1>ÜRÜNLERİMİZ</h1>
            </div>
            <div className="row ">
               {renderList}
            </div>
         </div>
         <div className="container">
            <div className="row">
               <h1>KAMPANYALI ÜRÜNLERİMİZ</h1>
            </div>
            <div className="row ">
               {campaigns}
            </div>
         </div>
      </section>
   );
}

export default ItemList;
